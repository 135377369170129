import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import JotformEmbed from "react-jotform-embed";

function ContactoLat() {
  return (
    <>
      <div className="m-4 pb-2 text-center">
        <h5 className="uppercase"> ponerse </h5>
        <h1 className="uppercase text-exoticberry"> en contacto </h1>
        <div className="prose-base text-center">
          <p>
            Si eres de Latinoamérica, puedes dejarnos tus dudas y nosotros te
            contactaremos.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
        <div className="flex w-full justify-center drop-shadow-xl">
          <StaticImage
            src="../assets/images/botellas-contacto.png"
            alt="Contacto Boones"
            className="h-full"
            placeholder="dominantColor"
          />
        </div>
        <div className="w-full text-center md:w-1/2">
          <JotformEmbed src="https://form.jotform.com/222217143047850" />
        </div>
      </div>
    </>
  );
}

export default ContactoLat;

/*
<form
          action=""
          className="flex w-full flex-col content-center items-center justify-center justify-items-center"
        >
          <label htmlFor="name" className="">
            ¿Cuál es tu nombre?
          </label>
          <input
            className="mb-6 w-1/2 rounded border-2 border-gray-200 py-1 px-3 outline-none focus:ring focus:ring-exoticberry"
            type="name"
            name="nombre"
            id="name"
          />
          <label htmlFor="email" className="">
            ¿Cuál es tu correo?
          </label>
          <input
            className="mb-6 w-1/2 rounded border-2 border-gray-200 py-1 px-3 outline-none focus:ring focus:ring-exoticberry
            "
            type="email"
            name="email"
            id="email"
          />
          <button className="w-1/2 rounded bg-exoticberry p-2 uppercase text-white">
            ENVIAR
          </button>
        </form>
        */
