import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function MinibannerPL2() {
  return (
    <div className="h-64 w-full max-w-lg space-x-4 space-y-2 rounded-xl bg-white drop-shadow-xl">
      <StaticImage
        src="../assets/images/minibanner-pink-lemonade.png"
        alt="Boones Pink Lemonade"
        className="h-full w-full rounded-xl object-cover"
      />
    </div>
  );
}
{
  /*
const Wrapper = styled.section`
  .single-minibanner {
    padding: 0;
  }
`
*/
}
export default MinibannerPL2;
