import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

function CoctelesconBoones() {
  return (
    <>
      <div>
        <StaticImage
          src="../assets/images/cocteles-boones-EB.png"
          alt="Cocteles con Boones Exotic Berry"
          className=""
          placeholder="tracedSVG"
        />
      </div>
      <div>
        <div className="pb-2 text-center">
          <h5 className="uppercase"> cómo preparar </h5>
          <h1 className="uppercase text-exoticberry"> cocteles con </h1>
          <StaticImage
            src="../assets/images/logo-boones.png"
            alt="Boones"
            className=""
            placeholder="tracedSVG"
          />
        </div>
        <div className="prose-base text-center">
          <p>
            ¡Con Boone’s es #ImposibleEquivocarse! Desde grandes creaciones,
            hasta un sencillo pero refrescante coctel. Aquí podrás encontrar un
            sinfín de recetas especialmente preparadas para darle un toque
            original a cualquier momento.
          </p>
          <Link to="/cocteles" className="center-txt">
            <button className="rounded bg-exoticberry p-2 text-white">
              PREPARAR TU COCTEL
            </button>
          </Link>
        </div>
      </div>
      <div>
        <StaticImage
          src="../assets/images/cocteles-boones-SPP.png"
          alt="Cocteles con Boones Sun Peak Peach"
          className=""
          placeholder="tracedSVG"
        />
      </div>
    </>
  );
}
export default CoctelesconBoones;
