import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import MinibannerPL1 from "../components/MinibannerPL1";
import MinibannerPL2 from "../components/MinibannerPL2";
import CoctelesconBoones from "../components/coctelesConBoones";
import BribeRecetas from "../components/BribeRecetas";
import Carruselsabores from "../components/CarruselSabores";
import BlogGridFeat from "../components/BlogGridFeatured";
import CarruselCompra from "../components/CarruselCompra";
import CarruselCompraLat from "../components/CarruselCompraLat";
import ContactoLat from "../components/ContactoLat";

export default function Home() {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: fecha }) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Inicio" }} />
      <main className>
        <header className="w-full">
          <StaticImage
            src="../assets/images/banner-home-asisabe.jpg"
            alt="Boones imposible equivocarse"
            className="z-0 rounded-3xl"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </header>
        <div className="container relative -mt-1 mb-20 md:-mt-5">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div>
              <MinibannerPL1 />
            </div>
            <div>
              <MinibannerPL2 />
            </div>
          </div>
        </div>
        <div className="container mt-6 mb-20 flex">
          <CoctelesconBoones />
        </div>
        <div className="container mt-6 mb-20">
          <BribeRecetas />
        </div>
        <div className="container mt-6 mb-20 flex-wrap justify-center">
          <Carruselsabores />
        </div>
        <div className="container mt-6 mb-20">
          <BlogGridFeat articles={allStrapiArticle.nodes} />
        </div>
        <div className="container mt-6 mb-20 rounded-2xl bg-white p-10 shadow-xl">
          <CarruselCompra />
        </div>
        <div className="container mt-6 mb-20 rounded-2xl bg-white p-10 shadow-xl">
          <CarruselCompraLat />
        </div>
        <div className="container mt-6 mb-20">
          <ContactoLat />
        </div>
      </main>
    </Layout>
  );
}
