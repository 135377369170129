import React from "react";
import { Link } from "gatsby";

function MinibannerPL1() {
  return (
    <div className="max-w-lg space-x-4 space-y-2 rounded-xl bg-white p-5 drop-shadow-xl md:h-64">
      <div className="w-28 rounded-xl bg-yellow-300 p-1 text-center text-xs text-pinklemonade">
        NUEVO SABOR
      </div>
      <h1 className="text-2xl">PINK LEMONADE</h1>
      <h5 className="text-lg font-light">Sabores a fresas y Limón</h5>
      <p className="max-w-prose text-sm font-light">
        El nuevo sabor de Boone’s Pink Lemonade es nuestra mezcla más vibrante
        gracias a su explosiva combinación de fresas y cítricos como el limón
        verde y amarillo.
      </p>
      <h5 className="text-xl font-light text-pinklemonade">#AsíSabe</h5>
      <Link
        to="/sabores/pink-lemonade"
        className="text-xs font-light uppercase text-pinklemonade"
      >
        VER MÁS ➤
      </Link>
    </div>
  );
}

export default MinibannerPL1;
