import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function CarruselCompraLat() {
  return (
    <>
      <div className="mb-4 pb-2 text-center">
        <h5 className="uppercase"> comprar </h5>
        <h1 className="uppercase text-deliciousapple"> BOONE´S </h1>
        <h5 className="uppercase"> en latinoamérica </h5>
      </div>
      <div className="flex snap-x flex-nowrap items-center justify-center gap-5 overflow-x-scroll">
        <StaticImage
          src="../assets/images/logos-tiendas/lat/cava16.jpg"
          alt="Boones Cava 16 Perú"
          className="flex-none snap-end snap-always scroll-ml-6 object-contain"
          placeholder="blurred"
          width={180}
          height={180}
          layout="constrained"
        />
        <StaticImage
          src="../assets/images/logos-tiendas/lat/exito_colombia.png"
          alt="Boones Exito Colombia"
          className="flex-none snap-end snap-always scroll-ml-6 object-contain"
          placeholder="blurred"
          width={180}
          height={180}
          layout="constrained"
        />
        <StaticImage
          src="../assets/images/logos-tiendas/lat/tia.png"
          alt="Boones Tia Ecuador"
          className="flex-none snap-end snap-always scroll-ml-6 object-contain"
          placeholder="blurred"
          width={250}
          height={150}
          layout="constrained"
        />
      </div>
    </>
  );
}

export default CarruselCompraLat;
